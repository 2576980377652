import * as React from 'react';
import { useFormView } from '@samc/screen-config-api';
import { ProgressIndicator, MessageBar, MessageBarType } from '@fluentui/react';
import AddLocationModalContent from './AddLocationModal.content';
export const AddLocationModal = (props) => {
    const { isOpen, onDismiss, viewId, headerText } = props;
    const result = useFormView(viewId);
    if (result.isLoading) {
        return React.createElement(ProgressIndicator, { label: "Loading..." });
    }
    if (result.isError) {
        return React.createElement(MessageBar, { messageBarType: MessageBarType.error }, `Error loading! ${result.error}`);
    }
    if (result.data) {
        return React.createElement(AddLocationModalContent, { isOpen: isOpen, onDismiss: onDismiss, headerText: headerText });
    }
    return React.createElement("h3", null, "Invalid ViewId!");
};
export default AddLocationModal;
