import * as React from 'react';
import { ViewType } from '@samc/screen-config-api';
import { FormScreenWrapper, GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import ViewModal from '@samc/screen-config-core/lib/organisms/modals/ViewModal/ViewModal';
import { AddCompanyWrapper } from './AddCompanyWrapper';
import '../../atoms/ViewModal.scss';
export const AddCompanyModalContent = (props) => {
    const { isOpen, headerText, onDismiss } = props;
    const [showForm, setShowForm] = React.useState(false);
    const continueToAddCheckboxChange = React.useCallback((isCheck) => {
        setShowForm(isCheck);
    }, []);
    const [primaryKeyValue, setPrimaryKeyValue] = React.useState();
    const defaultData = React.useMemo(() => {
        return {};
    }, []);
    return (React.createElement(ViewModal, { viewId: "AddCompany", viewType: ViewType.Form, isOpen: isOpen, headerText: headerText, onDismiss: onDismiss, primaryKeyValue: primaryKeyValue, setPrimaryKeyValue: setPrimaryKeyValue, suppressSaveButton: true, showContent: showForm, submitDisabledOverride: showForm ? undefined : true, defaultData: defaultData, FormScreenWrapper: FormScreenWrapper, GridScreenWrapper: GridScreenWrapper, ServiceDrivenViewSet: ServiceDrivenViewSet },
        React.createElement("div", null,
            React.createElement(AddCompanyWrapper, { continueToAddCheckboxChange: continueToAddCheckboxChange }))));
};
export default AddCompanyModalContent;
