import * as React from 'react';
import { GridView } from '../../atoms/GridView/GridView';
const columns = [
    { field: 'companyName', headerName: 'Company' },
    { field: 'foundIn', displayNameRule: 'Found In' },
    { field: 'shortName', displayNameRule: 'Short Name' },
    { field: 'companyType', displayNameRule: 'Company Type' },
    { field: 'companyStatus', displayNameRule: 'Company Status' },
    { field: 'globalDateImported', displayNameRule: 'Date Imported' },
];
const SimilarRecordCompanyGrid = (props) => {
    const { data } = props;
    return React.createElement(GridView, { data: data, columns: columns, title: "SIMILAR COMPANY(S) DETECTED" });
};
export default SimilarRecordCompanyGrid;
