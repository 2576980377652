var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useApiContext } from '../../../../api/ApiContext';
import { SimilarCompanyRecordDetectedColumnName, CompanyDomainId } from '../../../../interfaces/Constants';
import { getCompanySimilarRecord } from '../../../../services/SimilarRecordService';
import { BaseSimilarRecordCheckRenderer } from '../BaseSimilarRecordCheckRenderer';
export const CompanySimilarRecordCheckBody = (props) => {
    const { node, data, setAcknowledgementRequiredForRow, deleteRows } = props;
    const similarRecordCheckData = (data && data[SimilarCompanyRecordDetectedColumnName] ? data[SimilarCompanyRecordDetectedColumnName] : undefined);
    const api = useApiContext();
    const [isAcknowledged, setIsAcknowledged] = React.useState((similarRecordCheckData === null || similarRecordCheckData === void 0 ? void 0 : similarRecordCheckData.isAcknowledged) || false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [similarRecords, setSimilarRecords] = React.useState(((similarRecordCheckData === null || similarRecordCheckData === void 0 ? void 0 : similarRecordCheckData.similarRecords) || []));
    const [companyName, setCompanyName] = React.useState(similarRecordCheckData === null || similarRecordCheckData === void 0 ? void 0 : similarRecordCheckData.companyName);
    React.useEffect(() => {
        if (similarRecordCheckData) {
            setCompanyName(similarRecordCheckData.companyName);
            setSimilarRecords((similarRecordCheckData.similarRecords || []));
            setIsAcknowledged(similarRecordCheckData.isAcknowledged || false);
        }
    }, [similarRecordCheckData]);
    React.useEffect(() => {
        if (!companyName || isAcknowledged)
            return;
        setIsLoading(true);
        const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const newSimilarRecords = yield getCompanySimilarRecord(api.CompanyCatalogApi, companyName !== null && companyName !== void 0 ? companyName : '', (_a = api.requestInit) !== null && _a !== void 0 ? _a : {});
            setSimilarRecords(newSimilarRecords);
            setIsLoading(false);
            node.setDataValue(SimilarCompanyRecordDetectedColumnName, {
                companyName,
                isAcknowledged,
                similarRecords: newSimilarRecords,
            });
        });
        fetch();
    }, [api.CompanyCatalogApi, api.requestInit, companyName, node, isAcknowledged]);
    return (React.createElement(BaseSimilarRecordCheckRenderer, { data: data, node: node, deleteRows: deleteRows, isLoading: isLoading, domainId: CompanyDomainId, similarRecords: similarRecords, isAcknowledged: isAcknowledged, setIsAcknowledged: setIsAcknowledged, similarRecordDetectedColumnName: SimilarCompanyRecordDetectedColumnName, setAcknowledgementRequiredForRow: setAcknowledgementRequiredForRow }));
};
export default CompanySimilarRecordCheckBody;
