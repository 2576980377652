import * as React from 'react';
import '../../atoms/ViewModal.scss';
import { RadioButton } from '../../atoms/RadioButton/RadioButton';
import { GridView } from '../../atoms/GridView/GridView';
import { ModalPopup } from '../../atoms/Modal/ModalPopup';
export const SimilarRecordModal = (props) => {
    const { isOpen, colunmName, headerText, onDismiss, rowIndex, data, onSubmit } = props;
    const [confirmationButtonDisable, setconfirmationButtonDisable] = React.useState(true);
    const [radioButtonValue, setRadioButtonValue] = React.useState();
    return (React.createElement(ModalPopup, { isOpen: isOpen, headerText: headerText, onDismiss: () => {
            onDismiss(false);
            setconfirmationButtonDisable(true);
        }, onSubmit: () => {
            onSubmit(radioButtonValue, rowIndex);
            setconfirmationButtonDisable(true);
        }, confirmationButtonDisable: confirmationButtonDisable, rejectionButtonText: "CANCEL", confirmationButtonText: "OK" },
        React.createElement(React.Fragment, null,
            React.createElement("div", null, data && React.createElement(GridView, { data: data, columns: colunmName, title: "" })),
            React.createElement("div", { className: "row" },
                React.createElement(RadioButton, { title: "Continue to Add as New", value: "ContinueToAdd", onChange: () => {
                        setRadioButtonValue('ContinueToAdd');
                        setconfirmationButtonDisable(false);
                    }, name: "gridRowOption", marginLeft: "10px" }),
                React.createElement(RadioButton, { title: "Cancel Add \u2013 Remove Row from Grid", value: "CancelAdd", onChange: () => {
                        setRadioButtonValue('CancelAdd');
                        setconfirmationButtonDisable(false);
                    }, name: "gridRowOption", marginLeft: "50px" })))));
};
export default SimilarRecordModal;
