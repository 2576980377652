import * as React from 'react';
import { GridView } from '../../atoms/GridView/GridView';
const columns = [
    { field: 'locationName', displayNameRule: 'Location Name' },
    { field: 'foundIn', headerName: 'Found In' },
    { field: 'locationType', displayNameRule: 'Location Type' },
    { field: 'locationCountry', displayNameRule: 'Location Country' },
    { field: 'locationCity', displayNameRule: 'Location City' },
    { field: 'locationStatus', displayNameRule: 'Location Status' },
    { field: 'globalDateImported', displayNameRule: 'Date Imported' },
];
const SimilarRecordLocationGrid = (props) => {
    const { data } = props;
    return React.createElement(GridView, { data: data, columns: columns, title: "SIMILAR LOCATION(S) DETECTED" });
};
export default SimilarRecordLocationGrid;
