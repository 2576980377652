import React from 'react';
export const Appbar = (props) => {
    const { children, height, className } = props;
    return (React.createElement("div", { className: className, style: {
            width: '100%',
            height: height || 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        } }, children));
};
export default Appbar;
