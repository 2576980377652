import React, { useState } from 'react';
import { useDebouncedEffect } from '@samc/react-ui-core';
import './LocationSimilarRecordSearchPanel.css';
import { CompanyPickList } from '../CompanyPickList/CompanyPickList';
export const LocationSimilarRecordSearchPanel = (props) => {
    const { onSearchClick, lblCompanyName, lblLocationName, buttonText, list, onSelectCompanyNameChange, onTxtLocationNameChange, } = props;
    const [txtCompanyId, setTxtCompanyId] = useState('');
    const [txtLocationName, setTxtLocationName] = useState('');
    useDebouncedEffect(() => {
        onSelectCompanyNameChange(txtCompanyId);
        onTxtLocationNameChange(txtLocationName);
    }, [txtCompanyId, txtLocationName], 150);
    const onCompanyNameChangeEvent = (selectTerm) => {
        setTxtCompanyId(selectTerm);
    };
    const onLocationNameChangeEvent = (searchTerm) => {
        setTxtLocationName(searchTerm);
    };
    const handleKeypress = (e) => {
        if (e.key === 'Enter' && !txtLocationName.trim() && !txtCompanyId.trim()) {
            e.preventDefault();
        }
        else if (e.key === 'Enter' && txtLocationName.trim() && txtCompanyId.trim()) {
            onSearchClick(txtCompanyId.trim(), txtLocationName.trim());
            e.preventDefault();
        }
        else if (e.key === 'Enter') {
            e.preventDefault();
        }
    };
    return (React.createElement("div", { className: "similar-record-outer" },
        React.createElement("div", null,
            React.createElement("label", { className: "similar-record-title " }, lblCompanyName),
            React.createElement(CompanyPickList, { companyList: list, onChange: (value) => {
                    onCompanyNameChangeEvent(value);
                } }),
            React.createElement("label", { className: "similar-record-title similar-record-label" }, lblLocationName),
            React.createElement("input", { className: "similar-record-inputfield", type: "text", value: txtLocationName.trimStart(), onChange: (item) => {
                    onLocationNameChangeEvent(item.target.value);
                }, onKeyDown: handleKeypress }),
            React.createElement("button", { type: "button", disabled: txtLocationName.trim() === '' || txtCompanyId.trim() === '', className: `button-to-link ${txtLocationName.trim() === '' || txtCompanyId.trim() === '' ? 'isDisabled' : 'similar-record-button'}`, onClick: () => {
                    onSearchClick(txtCompanyId.trim(), txtLocationName.trim());
                } }, buttonText))));
};
export default LocationSimilarRecordSearchPanel;
