// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c62ff5381f/0/cache/css-loader-npm-7.1.2-7540f12884-15bfd90d77.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c62ff5381f/0/cache/css-loader-npm-7.1.2-7540f12884-15bfd90d77.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-application\\:\\@samc\\/single-spa-company-catalog .theme--custom.fluent-theme-custom {
    display: flex;
}

#single-spa-application\\:\\@samc\\/single-spa-company-catalog .record-picker-header {
    padding: 6px;
}

#single-spa-application\\:\\@samc\\/single-spa-company-catalog button, #single-spa-application\\:\\@samc\\/single-spa-company-catalog input, #single-spa-application\\:\\@samc\\/single-spa-company-catalog optgroup, #single-spa-application\\:\\@samc\\/single-spa-company-catalog select, #single-spa-application\\:\\@samc\\/single-spa-company-catalog textarea {
    line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/style/company-catalog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".theme--custom.fluent-theme-custom {\n    display: flex;\n}\n\n.record-picker-header {\n    padding: 6px;\n}\n\nbutton, input, optgroup, select, textarea {\n    line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
