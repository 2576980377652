import * as React from 'react';
import { GridView } from '../../atoms/GridView/GridView';
const columns = [
    { field: 'companyName', headerName: 'Company' },
    { field: 'foundIn', headerName: 'Found In' },
    { field: 'name', displayNameRule: 'Name' },
    { field: 'emailAddress', displayNameRule: 'Email Address' },
    { field: 'contactStatus', displayNameRule: 'Contact Status' },
    { field: 'globalDateImported', displayNameRule: 'Date Imported' },
];
const SimilarRecordContactGrid = (props) => {
    const { data } = props;
    return React.createElement(GridView, { data: data, columns: columns, title: "SIMILAR CONTACT(S) DETECTED" });
};
export default SimilarRecordContactGrid;
