import React from 'react';
import { AppraiserLicenseVerifyColumnName, CompanyDomainId, ContactDomainId, LocationDomainId, SimilarContactRecordDetectedColumnName, SimilarCompanyRecordDetectedColumnName, SimilarLocationRecordDetectedColumnName, SimilarRecordButtonTooltip, } from '../interfaces/Constants';
import { CompanySimilarRecordCheckRenderer } from '../components/molecules/cell-renderers/CompanySimilarRecordCheck/CompanySimilarRecordCheck.renderer';
import { ContactSimilarRecordCheckRenderer } from '../components/molecules/cell-renderers/ContactSimilarRecordCheck/ContactSimilarRecordCheck.renderer';
import { LocationSimilarRecordCheckRenderer } from '../components/molecules/cell-renderers/LocationSimilarRecordCheck/LocationSimilarRecordCheck.renderer';
import { AppraiserLicenseVerificationRenderer } from '../components/molecules/cell-renderers/AppraiserLicenseVerification/AppraiserLicenseVerificationRenderer';
const GetSimilarRecordCheckCustomColumns = (setAcknowledgementRequiredForRow) => {
    const getRequiredAckToolTip = (params) => {
        var _a;
        const { value } = params;
        if (value && !(value === null || value === void 0 ? void 0 : value.isAcknowledged) && ((_a = value === null || value === void 0 ? void 0 : value.similarRecords) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            return SimilarRecordButtonTooltip;
        }
        return '';
    };
    const customColumn = {
        customColumns: [
            {
                index: 0,
                gridSelector: CompanyDomainId,
                field: SimilarCompanyRecordDetectedColumnName,
                editableRule: 'false',
                displayNameRule: `Similar Company Detected`,
                hide: ({ editable }) => !editable,
                cellRenderer: (params) => (React.createElement(CompanySimilarRecordCheckRenderer, Object.assign({}, params, { setAcknowledgementRequiredForRow: setAcknowledgementRequiredForRow }))),
                tooltipValueGetter: getRequiredAckToolTip,
            },
            {
                index: 0,
                gridSelector: ContactDomainId,
                field: SimilarContactRecordDetectedColumnName,
                editableRule: 'false',
                displayNameRule: `Similar Contact Detected`,
                hide: ({ editable }) => !editable,
                cellRenderer: (params) => (React.createElement(ContactSimilarRecordCheckRenderer, Object.assign({}, params, { setAcknowledgementRequiredForRow: setAcknowledgementRequiredForRow }))),
                tooltipValueGetter: getRequiredAckToolTip,
            },
            {
                index: 0,
                gridSelector: LocationDomainId,
                field: SimilarLocationRecordDetectedColumnName,
                editableRule: 'false',
                displayNameRule: `Similar Location Detected`,
                hide: ({ editable }) => !editable,
                cellRenderer: (params) => (React.createElement(LocationSimilarRecordCheckRenderer, Object.assign({}, params, { setAcknowledgementRequiredForRow: setAcknowledgementRequiredForRow }))),
                tooltipValueGetter: getRequiredAckToolTip,
            },
            {
                index: 15,
                gridSelector: 'AppraiserLicense',
                field: AppraiserLicenseVerifyColumnName,
                editableRule: 'false',
                displayNameRule: `Verify`,
                hide: ({ editable }) => !editable,
                width: 80,
                cellRenderer: AppraiserLicenseVerificationRenderer,
            },
        ],
        onChangeData: (value) => {
            var _a;
            const { cellValueChangedEvent } = value;
            if (cellValueChangedEvent) {
                const columns = (_a = cellValueChangedEvent === null || cellValueChangedEvent === void 0 ? void 0 : cellValueChangedEvent.api) === null || _a === void 0 ? void 0 : _a.getColumns();
                const { data, node, column } = cellValueChangedEvent;
                const columnName = column.getColId();
                const isAppraiserLicenseGrid = columns === null || columns === void 0 ? void 0 : columns.find((x) => x.getColId() === AppraiserLicenseVerifyColumnName);
                if (isAppraiserLicenseGrid) {
                    if (columnName !== 'VerificationStatusId' && (data === null || data === void 0 ? void 0 : data.VerificationStatusId) !== '-') {
                        node.setDataValue('VerificationStatusId', 'NotVerified');
                    }
                }
                let rowData;
                if (!(data === null || data === void 0 ? void 0 : data._newRowId)) {
                    return;
                }
                const similarRecordDetectedColumn = columns === null || columns === void 0 ? void 0 : columns.find((x) => x.getColId() === SimilarCompanyRecordDetectedColumnName ||
                    x.getColId() === SimilarContactRecordDetectedColumnName ||
                    x.getColId() === SimilarLocationRecordDetectedColumnName);
                const similarRecordDetectedCol = similarRecordDetectedColumn === null || similarRecordDetectedColumn === void 0 ? void 0 : similarRecordDetectedColumn.getColId();
                if (similarRecordDetectedCol &&
                    similarRecordDetectedCol === SimilarCompanyRecordDetectedColumnName &&
                    columnName === 'CompanyName') {
                    rowData = {
                        companyName: cellValueChangedEvent === null || cellValueChangedEvent === void 0 ? void 0 : cellValueChangedEvent.newValue,
                    };
                    node.setDataValue(SimilarCompanyRecordDetectedColumnName, rowData, 'SimilarRecordCheckCustomColumns');
                }
                else if (similarRecordDetectedCol &&
                    similarRecordDetectedCol === SimilarContactRecordDetectedColumnName &&
                    (columnName === 'FirstName' || columnName === 'LastName')) {
                    rowData = {
                        firstName: data === null || data === void 0 ? void 0 : data.FirstName,
                        lastName: data === null || data === void 0 ? void 0 : data.LastName,
                    };
                    node.setDataValue(SimilarContactRecordDetectedColumnName, rowData, 'SimilarRecordCheckCustomColumns');
                }
                else if (similarRecordDetectedColumn &&
                    similarRecordDetectedCol === SimilarLocationRecordDetectedColumnName &&
                    (columnName === 'LocationName' || columnName === 'CompanyId')) {
                    rowData = {
                        companyId: data === null || data === void 0 ? void 0 : data.CompanyId,
                        locationName: data === null || data === void 0 ? void 0 : data.LocationName,
                    };
                    node.setDataValue(SimilarLocationRecordDetectedColumnName, rowData, 'SimilarRecordCheckCustomColumns');
                }
            }
        },
    };
    return customColumn;
};
export default GetSimilarRecordCheckCustomColumns;
