import React, { useState } from 'react';
import { useDebouncedEffect } from '@samc/react-ui-core';
import './ContactSimilarRecordSearchPanel.css';
export const ContactSimilarRecordSearchPanel = (props) => {
    const { onSearchClick, lblFirstName, lblLastName, buttonText, onTxtFirstNameChange, onTxtLastNameChange } = props;
    const [txtFirstName, setTxtFirstName] = useState('');
    const [txtLastName, setTxtLastName] = useState('');
    useDebouncedEffect(() => {
        onTxtFirstNameChange(txtFirstName);
    }, [txtFirstName], 150);
    useDebouncedEffect(() => {
        onTxtLastNameChange(txtLastName);
    }, [txtLastName], 150);
    const onFirstNameChangeEvent = (searchTerm) => {
        setTxtFirstName(searchTerm);
    };
    const onLastNameChangeEvent = (searchTerm) => {
        setTxtLastName(searchTerm);
    };
    const handleKeypress = (e) => {
        if (e.key === 'Enter' && !txtFirstName.trim() && !txtLastName.trim()) {
            e.preventDefault();
        }
        else if (e.key === 'Enter' && (txtFirstName.trim() || txtLastName.trim())) {
            onSearchClick(txtFirstName.trim(), txtLastName.trim());
            e.preventDefault();
        }
    };
    return (React.createElement("div", { className: "similar-record-outer" },
        React.createElement("div", null,
            React.createElement("label", { id: "lblFirstName", className: "similar-record-title" }, lblFirstName),
            React.createElement("input", { name: "txtFirstName", className: "similar-record-inputfield", type: "text", value: txtFirstName === null || txtFirstName === void 0 ? void 0 : txtFirstName.trimLeft(), onChange: (item) => onFirstNameChangeEvent(item.target.value), onKeyPress: handleKeypress }),
            React.createElement("label", { id: "lblLastName", className: "similar-record-title similar-record-label" }, lblLastName),
            React.createElement("input", { name: "txtLastName", className: "similar-record-inputfield", type: "text", value: txtLastName.trimLeft(), onChange: (item) => onLastNameChangeEvent(item.target.value), onKeyPress: handleKeypress }),
            React.createElement("button", { type: "button", disabled: txtFirstName.trim() === '' || txtLastName.trim() === '', className: `button-to-link ${txtFirstName.trim() === '' || txtLastName.trim() === '' ? 'isDisabled' : 'similar-record-button'}`, onClick: () => {
                    onSearchClick(txtFirstName.trim(), txtLastName.trim());
                } }, buttonText))));
};
export default ContactSimilarRecordSearchPanel;
