import React from 'react';
import { useStyletron } from 'styletron-react';
export const LinkButton = (props) => {
    const { text, onClick, style, parentStyle } = props;
    const [css] = useStyletron();
    return (React.createElement("div", { className: css(Object.assign({}, parentStyle)) },
        React.createElement("button", { type: "button", onClick: onClick, className: css(Object.assign({
                border: 0,
                background: 'transparent',
                cursor: 'pointer',
                color: '#214d88',
            }, style)) }, text)));
};
export default LinkButton;
