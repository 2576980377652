import * as React from 'react';
export const RadioButton = (props) => {
    const { title, value, onChange, name, marginLeft } = props;
    return (React.createElement("label", { htmlFor: name, style: { color: 'blue', cursor: 'pointer', marginLeft: marginLeft !== null && marginLeft !== void 0 ? marginLeft : '0px' } },
        React.createElement("input", { style: { cursor: 'pointer', marginTop: '0px', marginRight: '3px' }, id: title, name: name, type: "radio", value: value, onChange: (item) => {
                onChange(item.target.value);
            } }),
        React.createElement("label", { style: { cursor: 'pointer' }, htmlFor: title }, title)));
};
export default RadioButton;
