import * as React from 'react';
import { useHeaderContext } from '@samc/react-ui-history';
import { useDeepCompareMemoize } from '@samc/react-ui-core';
export const AcknowledgementRequiredContext = React.createContext({
    setAcknowledgementRequiredForRow: () => {
        console.error('Using the default implementation of AcknowledgeRequiredContext.setAcknowledgementRequiredForRow - this means the context is being consumed outside of a valid provider, which is likely a bug');
    },
});
export const AcknowledgementRequiredContextProvider = ({ children, }) => {
    const [rowsRequiringAcknowledgement, setRowsRequiringAcknowledgement] = React.useState({});
    const { setValue } = useHeaderContext();
    const rowsRequiringAcknowledgementMemoize = useDeepCompareMemoize(rowsRequiringAcknowledgement);
    React.useEffect(() => {
        setValue('controlBarProps', (c) => (Object.assign(Object.assign({}, c), { submitDisabled: Object.values(rowsRequiringAcknowledgementMemoize).some((v) => v === true) ? true : undefined })));
    }, [rowsRequiringAcknowledgementMemoize, setValue]);
    const setAcknowledgementRequiredForRow = React.useCallback((key, req) => {
        setRowsRequiringAcknowledgement((prev) => (Object.assign(Object.assign({}, prev), { [key]: req })));
    }, []);
    return (React.createElement(AcknowledgementRequiredContext.Provider, { value: { setAcknowledgementRequiredForRow } }, children));
};
export const useAcknowledgementRequiredContext = () => React.useContext(AcknowledgementRequiredContext);
