import * as React from 'react';
export const CheckBox = (props) => {
    const { color, text, onChange } = props;
    return (React.createElement("div", { style: { padding: '5px' } },
        React.createElement("label", { htmlFor: "chkContinueToAdd", style: { color: color !== null && color !== void 0 ? color : 'blue', cursor: 'pointer' } },
            React.createElement("input", { style: { cursor: 'pointer' }, id: "chkContinueToAdd", type: "checkbox", onChange: (e) => {
                    onChange(e.target.checked);
                } }),
            React.createElement("span", null,
                " ",
                text))));
};
export default CheckBox;
