var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { useState } from 'react';
import { useFormContext } from '@samc/react-ui-form';
import { useApiContext } from '../../../api/ApiContext';
import { ContactSimilarRecordSearchPanel } from '../../atoms/ContactSimilarSearch/ContactSimilarRecordSearchPanel';
import ContactSimilarRecordGrid from '../ContactSimilarRecordGrid/SimilarRecordContactGrid';
import { apiProvider } from '../../../api/Provider';
import { CheckBox } from '../../atoms/CheckBox/CheckBox';
export const AddContactWrapper = (props) => {
    const { continueToAddCheckboxChange } = props;
    const [similarRecordData, setSimilarRecordData] = React.useState();
    const api = useApiContext();
    const formContext = useFormContext();
    const [continueToAddAsNew, setContinueToAddAsNew] = useState(false);
    const getSimilarRecords = (txtFirstName, txtLastName) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!txtFirstName || !txtLastName)
            return;
        const responseJson = yield apiProvider.getData(`${api.CompanyCatalogApi}/api/Contact/SimilarRecord?firstName=${txtFirstName}&lastName=${txtLastName}`, (_a = api.requestInit) !== null && _a !== void 0 ? _a : {});
        const listSimilarRec = responseJson === null || responseJson === void 0 ? void 0 : responseJson.map((element) => ({
            contactId: element.contactId,
            action: 'Use this contact',
            companyName: element.companyName,
            foundIn: 'This Catalog',
            name: element.name,
            emailAddress: element.emailAddress,
            contactStatus: element.contactStatus,
            globalDateImported: element.globalDateImported,
        }));
        setSimilarRecordData(listSimilarRec);
        setContinueToAddAsNew(true);
    });
    const onSelectCheckBox = (isCheck) => {
        formContext.onChange('ViewType', 1, true);
        if (continueToAddCheckboxChange)
            continueToAddCheckboxChange(isCheck);
    };
    const setTxtFirstName = (txtFirstNameValue) => {
        if (txtFirstNameValue) {
            formContext.onChange('FirstName', txtFirstNameValue, true);
        }
    };
    const setTxtLastName = (txtLastNameValue) => {
        if (txtLastNameValue) {
            formContext.onChange('LastName', txtLastNameValue, true);
        }
    };
    return (React.createElement("div", null,
        React.createElement(ContactSimilarRecordSearchPanel, { onSearchClick: getSimilarRecords, lblFirstName: "First Name", lblLastName: "Last Name", buttonText: "Check For Similar Contacts", onTxtFirstNameChange: setTxtFirstName, onTxtLastNameChange: setTxtLastName }),
        similarRecordData && React.createElement(ContactSimilarRecordGrid, { data: similarRecordData }),
        continueToAddAsNew && (React.createElement(CheckBox, { text: "Continue To Add As New", onChange: (isChecked) => onSelectCheckBox(isChecked) }))));
};
export default AddContactWrapper;
