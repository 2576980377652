import * as React from 'react';
import {
  ActionOverrideContextProvider,
  ServiceDrivenViewSet,
  GridSelectionProvider,
  ViewSetSubtabChangedEvent,
  getTabRedirectString,
} from '@samc/screen-config-core';
import { ErrorBoundary } from '@samc/react-ui-core';
import { EditingProvider } from '@samc/react-ui-grid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddCompanyModal from '@samc/company-catalog/lib/components/molecules/AddCompanyModal/AddCompanyModal';
import AddContactModal from '@samc/company-catalog/lib/components/molecules/AddContactModal/AddContactModal';
import { AddLocationModal } from '@samc/company-catalog/lib/components/molecules/AddLocationModal/AddLocationModal';
import GetSimilarRecordCheckCustomColumns from '@samc/company-catalog/lib/contexts/SimilarRecordCheckCustomColumns';
import { ViewSetAction } from '@samc/screen-config-api';
import {
  HeaderContextProvider,
  RoutingWrapper,
  useHeaderContext,
  useRouterBlockerAndPromptForUnsavedChanges,
} from '@samc/react-ui-history';
import { ImportSitusAMCApprovedAppraisers } from '@samc/company-catalog/lib/components/molecules/ApprovedAppraisers/ImportSitusAMCApprovedAppraisers';
import { AcknowledgementRequiredContextProvider, useAcknowledgementRequiredContext } from '@samc/company-catalog';
import { EvaluatorConfigurationProvider } from '@samc/expressions-react';

interface ViewSetProps {
  showAddModal: string;
  setShowAddModal: (modalId: string) => void;
  onTabChanged: (newId: string) => void;
  onSubtabChanged: (ev: ViewSetSubtabChangedEvent) => void;
  tabId?: string;
  subtabId?: string;
}

const ViewSetMain = (viewSetProps: ViewSetProps): React.ReactElement => {
  const { showAddModal, setShowAddModal, tabId, onTabChanged, subtabId, onSubtabChanged } = viewSetProps;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  useRouterBlockerAndPromptForUnsavedChanges();

  const primaryKeyValue = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('primaryKeyValue') || '';
  }, [location.search]);

  React.useEffect(() => {
    setShowAddModal(showAddModal);
  }, [showAddModal, setShowAddModal]);

  const handleSetPrimaryKey = React.useCallback(
    (value: string | undefined): void => {
      const params = new URLSearchParams(location.search);

      if (value) {
        params.set('primaryKeyValue', value);
        navigate(`${location.pathname}?${params.toString()}`);
      }
    },
    [location.pathname, location.search, navigate],
  );

  return (
    <ErrorBoundary>
      <div style={{ display: 'flex', flexDirection: 'column', flex: '1 0 auto' }}>
        {showAddModal === 'company' && (
          <AddCompanyModal
            viewId="AddCompany"
            isOpen
            headerText="Add New Company"
            onDismiss={(): void => setShowAddModal('')}
          />
        )}
        {showAddModal === 'contact' && (
          <AddContactModal
            viewId="AddContact"
            isOpen
            headerText="Add New Contact"
            onDismiss={(): void => setShowAddModal('')}
          />
        )}
        {showAddModal === 'location' && (
          <AddLocationModal
            viewId="AddLocation"
            isOpen
            headerText="Add New Location"
            onDismiss={(): void => setShowAddModal('')}
          />
        )}
        {showAddModal === 'importSitusAMCApprovedAppraisers' && (
          <ImportSitusAMCApprovedAppraisers
            isOpen
            headerText="Import SitusAMC Verified Appraisers"
            onDismiss={(): void => {
              setShowAddModal('');
            }}
            onSuccess={(): void => {
              setShowAddModal('');
            }}
          />
        )}

        {id ? (
          // key here is set this way so that when the porfolio filter is changed, it will recreate/reload the data
          <ServiceDrivenViewSet
            key={id}
            viewSetId={id}
            primaryKeyValue={primaryKeyValue}
            setPrimaryKeyValue={handleSetPrimaryKey}
            initialSelectedTabId={tabId}
            onTabChanged={onTabChanged}
            onSubtabChanged={onSubtabChanged}
            initialSelectedSubtabId={subtabId}
            parentScope={`CompanyCatalog_${tabId}`}
          />
        ) : (
          <div>Please specify a ViewSet Id.</div>
        )}
      </div>
    </ErrorBoundary>
  );
};

const ViewSetPageInner = ({
  showAddModal,
  setShowAddModal,
}: {
  showAddModal: string;
  setShowAddModal: (modalId: string) => void;
}): React.ReactElement => {
  const originalLocation = useLocation();
  const navigate = useNavigate();
  const { tabId: urlTabId, subtabId: urlSubtabId } = useParams();
  const { tabs } = useHeaderContext();
  const { setAcknowledgementRequiredForRow } = useAcknowledgementRequiredContext();

  const customColumns = React.useMemo(() => {
    return GetSimilarRecordCheckCustomColumns(setAcknowledgementRequiredForRow);
  }, [setAcknowledgementRequiredForRow]);

  const onTabIdChanged = React.useCallback(
    (newId: string, userRequested?: boolean) => {
      if (newId === urlTabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: newId,
          newSubtabId: undefined, // should clear it on tab change
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  const onSubtabIdChanged = React.useCallback(
    (ev: ViewSetSubtabChangedEvent) => {
      const { newId, userRequested } = ev;

      if (newId === urlSubtabId) return; // do nothing if id doesn't change

      navigate(
        getTabRedirectString({
          originalLocation,
          oldTabId: urlTabId,
          oldSubtabId: urlSubtabId,
          newTabId: urlTabId,
          newSubtabId: newId,
        }),
        { replace: !userRequested },
      );
    },
    [navigate, originalLocation, urlSubtabId, urlTabId],
  );

  const onTabIndexChanged = React.useCallback(
    (newIndex: number, userRequested: boolean) => {
      if (!tabs) return;

      const tab = tabs[newIndex];
      if (!tab) return;

      onTabIdChanged(tab.id, userRequested);
    },
    [onTabIdChanged, tabs],
  );

  return (
    <EditingProvider value={customColumns}>
      <GridSelectionProvider>
        <RoutingWrapper onTabChanged={onTabIndexChanged}>
          <ViewSetMain
            tabId={urlTabId}
            onTabChanged={onTabIdChanged}
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            subtabId={urlSubtabId}
            onSubtabChanged={onSubtabIdChanged}
          />
        </RoutingWrapper>
      </GridSelectionProvider>
    </EditingProvider>
  );
};

export const ViewSetPage = (): React.ReactElement => {
  const [showAddModal, setShowAddModal] = React.useState<string>('');

  const actionsClick = React.useCallback(({ viewId }: ViewSetAction): void => {
    switch (viewId) {
      case 'AddCompany':
        setShowAddModal('company');
        break;
      case 'AddContact':
        setShowAddModal('contact');
        break;
      case 'AddLocation':
        setShowAddModal('location');
        break;
      default:
        setShowAddModal('importSitusAMCApprovedAppraisers');
        break;
    }
  }, []);

  const onClickActionOverrides = React.useMemo(
    () => ({
      AddCompany: actionsClick,
      AddContact: actionsClick,
      AddLocation: actionsClick,
      ImportSitusAMCApprovedAppraisers: actionsClick,
    }),
    [actionsClick],
  );

  return (
    <div style={{ flex: 1 }}>
      <EvaluatorConfigurationProvider value={{}}>
        <ActionOverrideContextProvider value={onClickActionOverrides}>
          <HeaderContextProvider>
            <AcknowledgementRequiredContextProvider>
              <ViewSetPageInner showAddModal={showAddModal} setShowAddModal={setShowAddModal} />
            </AcknowledgementRequiredContextProvider>
          </HeaderContextProvider>
        </ActionOverrideContextProvider>
      </EvaluatorConfigurationProvider>
    </div>
  );
};

export default ViewSetPage;
