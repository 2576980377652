// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../css-loader-virtual-c62ff5381f/0/cache/css-loader-npm-7.1.2-7540f12884-15bfd90d77.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../css-loader-virtual-c62ff5381f/0/cache/css-loader-npm-7.1.2-7540f12884-15bfd90d77.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.similar-record-outer {
  padding-top: 10px;
  padding-left: 10px;
}
.similar-record-title {
  color: blue;
}
.similar-record-inputfield {
  opacity: 1;
  mix-blend-mode: normal;
  background-color: rgb(255, 207, 207);
  border-color: rgb(219, 219, 219);
  border-width: 1px;
  color: rgb(30, 36, 42);
  padding: 0px 8px;
  text-align: left;
  border-radius: 2px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  background-image: none;
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
  border-style: solid;
  width: 240px;
  height: 25px;
  margin-left: 15px;
}
.similar-record-button:hover {
  cursor: pointer;
}
.similar-record-button {
  margin-left: 15px;
  color: blue;
}
.isDisabled {
  margin-left: 15px;
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.button-to-link {
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-company-catalog-virtual-7d7dcf77c6/1/packages/company-catalog/lib/components/atoms/LocationSimilarSearch/LocationSimilarRecordSearchPanel.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,UAAU;EACV,sBAAsB;EACtB,oCAAoC;EACpC,gCAAgC;EAChC,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,kCAAkC;EAClC,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE,SAAS;EACT,uBAAuB;EACvB,eAAe;EACf,YAAY;AACd","sourcesContent":[".similar-record-outer {\n  padding-top: 10px;\n  padding-left: 10px;\n}\n.similar-record-title {\n  color: blue;\n}\n.similar-record-inputfield {\n  opacity: 1;\n  mix-blend-mode: normal;\n  background-color: rgb(255, 207, 207);\n  border-color: rgb(219, 219, 219);\n  border-width: 1px;\n  color: rgb(30, 36, 42);\n  padding: 0px 8px;\n  text-align: left;\n  border-radius: 2px;\n  font-size: 14px;\n  font-weight: normal;\n  font-style: normal;\n  background-image: none;\n  background-position: center center;\n  background-repeat: repeat;\n  background-size: cover;\n  border-style: solid;\n  width: 240px;\n  height: 25px;\n  margin-left: 15px;\n}\n.similar-record-button:hover {\n  cursor: pointer;\n}\n.similar-record-button {\n  margin-left: 15px;\n  color: blue;\n}\n.isDisabled {\n  margin-left: 15px;\n  color: currentColor;\n  cursor: not-allowed;\n  opacity: 0.5;\n  text-decoration: none;\n  pointer-events: none;\n}\n\n.button-to-link {\n  border: 0;\n  background: transparent;\n  cursor: pointer;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
