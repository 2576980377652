import { Grid } from '@samc/react-ui-grid';
import * as React from 'react';
const noop = () => undefined;
export const GridView = (props) => {
    const { data, columns, title, rowsPerPage = 5 } = props;
    return (React.createElement("div", { style: { flexGrow: 1 } },
        React.createElement("div", null,
            React.createElement("p", { style: { fontWeight: 'bold', marginBottom: '5px', margin: '10px' } }, title)),
        React.createElement("div", null,
            React.createElement(Grid, { onSubmit: noop, data: data, fields: columns, rowSelection: "none", lockPaging: true, suppressRowSelector: true, suppressColumnFilters: true, suppressFullScreen: true, suppressEditToggle: true, suppressDeleteButton: true, suppressRowSpacing: true, suppressExcelExport: true, rowsPerPage: rowsPerPage, style: {
                    width: 'auto',
                    margin: '10px',
                    display: 'flex',
                    flex: '1 0 auto',
                    flexDirection: 'column',
                    height: '150px',
                } }))));
};
export default GridView;
