var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { useState } from 'react';
import { useFormContext } from '@samc/react-ui-form';
import { SimilarRecordSearchPanel } from '../../atoms/CompanySimilarSearch/SimilarRecordSearchPanel';
import SimilarRecordCompanyGrid from '../CompanySimilarRecordGrid/SimilarRecordCompanyGrid';
import { apiProvider } from '../../../api/Provider';
import { CheckBox } from '../../atoms/CheckBox/CheckBox';
import { useApiContext } from '../../../api/ApiContext';
export const AddCompanyWrapper = (props) => {
    const { continueToAddCheckboxChange } = props;
    const api = useApiContext();
    const formContext = useFormContext();
    const [similarRecordData, setSimilarRecordData] = React.useState();
    const [continueToAddAsNew, setContinueToAddAsNew] = useState(false);
    const getSimilarRecords = (txtSemilar) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const responseJson = yield apiProvider.getData(`${api.CompanyCatalogApi}/api/Company/SimilarRecord?name=${txtSemilar}`, (_a = api.requestInit) !== null && _a !== void 0 ? _a : {});
        const listSimilarRec = responseJson === null || responseJson === void 0 ? void 0 : responseJson.map((element) => ({
            companyId: element.companyId,
            action: 'Use this company',
            companyName: element.companyName,
            foundIn: 'This Catalog',
            shortName: element.shortName,
            companyType: element.companyType,
            companyStatus: element.companyStatus,
            globalDateImported: element.globalDateImported,
        }));
        setSimilarRecordData(listSimilarRec);
        setContinueToAddAsNew(true);
    });
    const onSelectCheckBox = (isCheck) => {
        formContext.onChange('ViewType', 1, true);
        if (continueToAddCheckboxChange)
            continueToAddCheckboxChange(isCheck);
    };
    const onSearchItemChanged = (txtSimilarCheckValue) => {
        if (txtSimilarCheckValue) {
            formContext.onChange('CompanyName', txtSimilarCheckValue, true);
        }
    };
    return (React.createElement("div", null,
        React.createElement(SimilarRecordSearchPanel, { onSearchClick: getSimilarRecords, labelText: "Company Name", buttonText: "Check For Similar Companies", onSearchItemChanged: onSearchItemChanged }),
        similarRecordData && React.createElement(SimilarRecordCompanyGrid, { data: similarRecordData }),
        continueToAddAsNew && (React.createElement(CheckBox, { text: "Continue To Add As New", onChange: (isChecked) => onSelectCheckBox(isChecked) }))));
};
export default AddCompanyWrapper;
