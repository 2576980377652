import React from 'react';
import CompanySimilarRecordCheckWrapper from './CompanySimilarRecordCheck.wrapper';
export const CompanySimilarRecordCheckRenderer = (props) => {
    const { node, data, setAcknowledgementRequiredForRow, deleteRows } = props;
    if (!(data === null || data === void 0 ? void 0 : data._newRowId)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(CompanySimilarRecordCheckWrapper, { node: node, data: data, setAcknowledgementRequiredForRow: setAcknowledgementRequiredForRow, deleteRows: deleteRows }));
};
export default CompanySimilarRecordCheckRenderer;
