// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../css-loader-virtual-c62ff5381f/0/cache/css-loader-npm-7.1.2-7540f12884-15bfd90d77.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../css-loader-virtual-c62ff5381f/0/cache/css-loader-npm-7.1.2-7540f12884-15bfd90d77.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.situs-theme-dark .header-root {
    background-color: #1e242a;
    transition: background-color .25s linear;
    color: #bababa;
    font-family: Roboto;
}

.situs-theme-dark .header-title {
    padding-left: 15px;
    font-size: 22px;
}

.situs-theme-dark .header-buttons {
    padding-right: 15px;
    height: 100%;
    margin-top: 14px;
}
`, "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@samc-company-catalog-virtual-7d7dcf77c6/1/packages/company-catalog/lib/components/atoms/Header/SitusHeader/SitusHeader.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,wCAAwC;IACxC,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".situs-theme-dark .header-root {\n    background-color: #1e242a;\n    transition: background-color .25s linear;\n    color: #bababa;\n    font-family: Roboto;\n}\n\n.situs-theme-dark .header-title {\n    padding-left: 15px;\n    font-size: 22px;\n}\n\n.situs-theme-dark .header-buttons {\n    padding-right: 15px;\n    height: 100%;\n    margin-top: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
