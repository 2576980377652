var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useApiContext } from '../../../../api/ApiContext';
import { SimilarContactRecordDetectedColumnName, ContactDomainId } from '../../../../interfaces/Constants';
import { getContactSimilarRecord } from '../../../../services/SimilarRecordService';
import { BaseSimilarRecordCheckRenderer } from '../BaseSimilarRecordCheckRenderer';
export const ContactSimilarRecordCheckBody = (props) => {
    const { node, data, setAcknowledgementRequiredForRow, deleteRows } = props;
    const similarRecordCheckData = (data && data[SimilarContactRecordDetectedColumnName] ? data[SimilarContactRecordDetectedColumnName] : undefined);
    const api = useApiContext();
    const [isAcknowledged, setIsAcknowledged] = React.useState((similarRecordCheckData === null || similarRecordCheckData === void 0 ? void 0 : similarRecordCheckData.isAcknowledged) || false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [similarRecords, setSimilarRecords] = React.useState(((similarRecordCheckData === null || similarRecordCheckData === void 0 ? void 0 : similarRecordCheckData.similarRecords) || []));
    const [firstName, setFirstName] = React.useState(similarRecordCheckData === null || similarRecordCheckData === void 0 ? void 0 : similarRecordCheckData.firstName);
    const [lastName, setLastName] = React.useState(similarRecordCheckData === null || similarRecordCheckData === void 0 ? void 0 : similarRecordCheckData.lastName);
    React.useEffect(() => {
        if (similarRecordCheckData) {
            setFirstName(similarRecordCheckData.firstName);
            setLastName(similarRecordCheckData.lastName);
            setSimilarRecords((similarRecordCheckData.similarRecords || []));
            setIsAcknowledged(similarRecordCheckData.isAcknowledged || false);
        }
    }, [similarRecordCheckData]);
    React.useEffect(() => {
        if (!firstName || !lastName || isAcknowledged)
            return;
        setIsLoading(true);
        const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const newSimilarRecords = yield getContactSimilarRecord(api.CompanyCatalogApi, firstName || '', lastName || '', (_a = api.requestInit) !== null && _a !== void 0 ? _a : {});
            setSimilarRecords(newSimilarRecords);
            setIsLoading(false);
            node.setDataValue(SimilarContactRecordDetectedColumnName, {
                firstName,
                lastName,
                isAcknowledged,
                similarRecords: newSimilarRecords,
            });
        });
        fetch();
    }, [api.CompanyCatalogApi, api.requestInit, firstName, lastName, node, isAcknowledged]);
    return (React.createElement(BaseSimilarRecordCheckRenderer, { data: data, node: node, deleteRows: deleteRows, isLoading: isLoading, domainId: ContactDomainId, similarRecords: similarRecords, isAcknowledged: isAcknowledged, setIsAcknowledged: setIsAcknowledged, similarRecordDetectedColumnName: SimilarContactRecordDetectedColumnName, setAcknowledgementRequiredForRow: setAcknowledgementRequiredForRow }));
};
export default ContactSimilarRecordCheckBody;
