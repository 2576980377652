var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useFormContext } from '@samc/react-ui-form';
import { apiProvider } from '../../../api/Provider';
import { CheckBox } from '../../atoms/CheckBox/CheckBox';
import { useApiContext } from '../../../api/ApiContext';
import { LocationSimilarRecordSearchPanel } from '../../atoms/LocationSimilarSearch/LocationSimilarRecordSearchPanel';
import SimilarRecordLocationGrid from '../LocationSimilarRecordGrid/SimilarRecordLocationGrid';
const AddLocationWrapper = (props) => {
    const { continueToAddCheckboxChange } = props;
    const api = useApiContext();
    const formContext = useFormContext();
    const [similarRecordData, setSimilarRecordData] = React.useState();
    const [continueToAddAsNew, setContinueToAddAsNew] = useState(false);
    const [companyList, setCompanyList] = React.useState([]);
    const getCompanyPickList = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const responseJson = yield apiProvider.getData(`${api.CompanyCatalogApi}/api/Location/CompanyPickList`, (_a = api.requestInit) !== null && _a !== void 0 ? _a : {});
            const compList = responseJson === null || responseJson === void 0 ? void 0 : responseJson.map((element) => ({
                companyName: element.companyName,
                id: element.id,
            }));
            setCompanyList(compList);
        }
        catch (err) {
            console.log(err);
        }
    }), [api.CompanyCatalogApi, api.requestInit]);
    useEffect(() => {
        getCompanyPickList();
    }, [getCompanyPickList]);
    const getSimilarRecords = (txtCompanyId, txtLocationName) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        try {
            const responseJson = yield apiProvider.getData(`${api.CompanyCatalogApi}/api/Location/SimilarRecord?companyId=${txtCompanyId}&locationName=${txtLocationName}`, (_b = api.requestInit) !== null && _b !== void 0 ? _b : {});
            const listSimilarRec = responseJson === null || responseJson === void 0 ? void 0 : responseJson.map((element) => ({
                locationId: element.locationId,
                locationName: element.locationName,
                foundIn: 'This Catalog',
                locationType: element.locationType,
                locationCountry: element.locationCountry,
                locationCity: element.locationCity,
                locationStatus: element.locationStatus,
                globalDateImported: element.globalDateImported,
            }));
            setSimilarRecordData(listSimilarRec);
            setContinueToAddAsNew(true);
        }
        catch (err) {
            console.log(err);
        }
    });
    const onSelectCheckBox = (isCheck) => {
        formContext.onChange('ViewType', 1, true);
        if (continueToAddCheckboxChange)
            continueToAddCheckboxChange(isCheck);
    };
    const setSelectedCompanyName = (txtCompanyNameValue) => {
        if (txtCompanyNameValue) {
            formContext.onChange('CompanyId', txtCompanyNameValue, true);
        }
    };
    const setTxtLocationName = (txtLocationName) => {
        if (txtLocationName) {
            formContext.onChange('LocationName', txtLocationName, true);
        }
    };
    return (React.createElement("div", null,
        React.createElement(LocationSimilarRecordSearchPanel, { onSearchClick: getSimilarRecords, lblCompanyName: "Company", lblLocationName: "Location Name", buttonText: "Check For Similar Locations", list: companyList, onSelectCompanyNameChange: setSelectedCompanyName, onTxtLocationNameChange: setTxtLocationName }),
        similarRecordData && React.createElement(SimilarRecordLocationGrid, { data: similarRecordData }),
        continueToAddAsNew && (React.createElement(CheckBox, { text: "Continue To Add As New", onChange: (isChecked) => onSelectCheckBox(isChecked) }))));
};
export default AddLocationWrapper;
