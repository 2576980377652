import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { EditingProvider } from '@samc/react-ui-grid';
import GridTest from './GridInitialize';
const queryClient = new QueryClient();
export const App = () => {
    return (React.createElement("div", null,
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(EditingProvider, null,
                React.createElement(GridTest, null)))));
};
export default App;
