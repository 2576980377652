import * as React from 'react';
import { Appbar } from '../Appbar/Appbar';
import { Button } from '../Button/Button';
export const Header = (props) => {
    const { title, confirmationButtonText, onConfirmationButtonClicked, rejectionButtonText, onRejectionButtonClicked, children, confirmationButtonDisable, } = props;
    return (React.createElement(Appbar, { className: "header-root" },
        React.createElement("div", { style: { marginRight: 'auto' }, className: "header-title" }, title),
        React.createElement("div", { className: "header-buttons" },
            children,
            onRejectionButtonClicked && (React.createElement(Button, { buttonType: "secondary", text: rejectionButtonText || 'Cancel', onClick: onRejectionButtonClicked })),
            React.createElement("span", { style: { marginLeft: '.5rem' } }),
            onConfirmationButtonClicked && (React.createElement(Button, { buttonType: "primary", text: confirmationButtonText || 'Ok', onClick: onConfirmationButtonClicked, disabled: confirmationButtonDisable })))));
};
export default Header;
