import * as React from 'react';
import { useDebouncedEffect } from '@samc/react-ui-core';
import './SimilarRecordSearchPanel.css';
export const SimilarRecordSearchPanel = (props) => {
    const { onSearchClick, labelText, buttonText, onSearchItemChanged } = props;
    const [txtSimilar, setTxtSimilar] = React.useState('');
    useDebouncedEffect(() => {
        onSearchItemChanged(txtSimilar);
    }, [txtSimilar], 150);
    const onSearchItemChangeEvent = (searchTerm) => {
        setTxtSimilar(searchTerm);
    };
    const handleKeypress = (e) => {
        if (e.key === 'Enter' && !txtSimilar.trim()) {
            e.preventDefault();
        }
        else if (e.key === 'Enter' && txtSimilar.trim()) {
            onSearchClick(txtSimilar.trim());
            e.preventDefault();
        }
    };
    return (React.createElement("div", { className: "similar-record-outer" },
        React.createElement("form", null,
            React.createElement("label", { id: "lblName", className: "similar-record-title" }, labelText),
            React.createElement("input", { className: "similar-record-inputfield", type: "text", value: txtSimilar.trimLeft(), onChange: (item) => onSearchItemChangeEvent(item.target.value), onKeyPress: handleKeypress }),
            React.createElement("button", { type: "button", disabled: txtSimilar.trim() === '', className: `button-to-link ${txtSimilar.trim() === '' ? 'isDisabled' : 'similar-record-button'}`, onClick: () => {
                    onSearchClick(txtSimilar.trim());
                } }, buttonText))));
};
export default SimilarRecordSearchPanel;
