var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { Grid, useAgGridApi } from '@samc/react-ui-grid';
import { HorizontalAlignment, toastError, ToastMessage, toastSuccess } from '@samc/react-ui-core';
import { ProgressIndicator } from '@fluentui/react';
import { useApiContext } from '../../../api/ApiContext';
import { ModalPopup } from '../../atoms/Modal/ModalPopup';
import { getApprovedAppraisers, importApprovedAppraisor } from '../../../services/GlobalImportService';
export const importGridColumns = [
    {
        field: 'key',
        editableRule: 'false',
        visibleRule: 'false',
        isKeyField: true,
    },
    {
        field: 'companyId',
        displayNameRule: 'Global Company Id',
        editableRule: 'false',
        visibleRule: 'false',
    },
    {
        field: 'prevImportDate',
        displayNameRule: 'Previous Import Date',
        type: 'date',
        displayFormat: 'DD-MMM-YYYY',
        horizontalAlignment: HorizontalAlignment.Left,
        sortable: false,
    },
    {
        field: 'companyName',
        displayNameRule: 'Company Name',
        type: 'string',
        sortable: false,
    },
    { field: 'shortName', displayNameRule: 'Short Name', sortable: false },
    { field: 'contactName', displayNameRule: 'Contact Name', sortable: false },
    { field: 'contactStatus', displayNameRule: 'Contact Status', sortable: false },
    { field: 'locationName', displayNameRule: "Contact's Primary Office Location", sortable: false },
    { field: 'locationType', displayNameRule: 'Primary Location Type', sortable: false },
    { field: 'locationCountry', displayNameRule: 'Primary Location Country', sortable: false },
    { field: 'locationCity', displayNameRule: 'Primary City/ District/ Municipality', sortable: false },
    { field: 'locationState', displayNameRule: 'Primary Province/ Region/ Country', sortable: false },
    { field: 'address1', displayNameRule: 'Primary Address Line 1', sortable: false },
    { field: 'address2', displayNameRule: 'Primary Address Line 2', sortable: false },
    { field: 'postalCode', displayNameRule: 'Primary Postal Code', sortable: false },
];
export const ImportSitusAMCApprovedAppraisers = (props) => {
    const { isOpen, headerText, onDismiss, onSuccess } = props;
    const [isLoading, setIsLoading] = React.useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = React.useState(true);
    const [listApprovedAppraisers, setListApprovedAppraisers] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const api = useApiContext();
    const [gridApi, onGridReady] = useAgGridApi();
    const getApprovedAppraiser = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const response = yield getApprovedAppraisers(api.CompanyCatalogApi, api.requestInit);
            setIsLoading(false);
            if (response.status === 'success') {
                setListApprovedAppraisers(response.result);
            }
            else if (response.error) {
                toastError(React.createElement(ToastMessage, { title: "Info", message: response.error }));
            }
        }
        catch (e) {
            setIsLoading(false);
        }
    }), [api.CompanyCatalogApi, api.requestInit]);
    const onSelectionChanged = (event) => {
        const selectedGridRows = event.api.getSelectedRows();
        const finalSelectedRows = selectedGridRows === null || selectedGridRows === void 0 ? void 0 : selectedGridRows.filter((x) => !x.prevImportDate);
        if (finalSelectedRows.length > 0) {
            setDisableSubmitButton(false);
        }
        else {
            setDisableSubmitButton(true);
        }
        setSelectedRows(finalSelectedRows);
    };
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const payload = selectedRows
                .filter((x) => !x.prevImportDate)
                .map((x) => ({
                companyId: x.companyId,
                contactId: x.contactId,
                companyName: x.companyName,
            }));
            setDisableSubmitButton(true);
            const res = yield importApprovedAppraisor(api.CompanyCatalogApi, payload, api.requestInit);
            if (res.status === 'DONE') {
                toastSuccess(React.createElement(ToastMessage, { title: "Success", message: "Selected Records Imported Successfully" }));
                onSuccess();
            }
            else if (res.status === 'FAILED') {
                toastError(React.createElement(ToastMessage, { title: "Failed", message: "Cannot import in Global catalog" }));
            }
            else {
                toastError(React.createElement(ToastMessage, { title: "Error", message: res.error }));
            }
            setDisableSubmitButton(false);
        }
        catch (_a) {
            setDisableSubmitButton(false);
            toastError(React.createElement(ToastMessage, { title: "Error", message: "Something went wrong !" }));
        }
    });
    React.useEffect(() => {
        getApprovedAppraiser();
    }, [getApprovedAppraiser]);
    const onGridStateChange = (params) => {
        setTimeout(() => {
            const start = params.page * params.rowsPerPage;
            const end = start + params.rowsPerPage;
            let filteredData = [...listApprovedAppraisers];
            const filterModel = params.filterModel;
            Object.keys(filterModel).forEach((key) => {
                filteredData = filteredData.filter((x) => {
                    var _a, _b, _c;
                    if (filterModel[key].filterType === 'date') {
                        if (!x[key]) {
                            return false;
                        }
                        if (new Date(x[key]).toDateString() === new Date(filterModel[key].dateFrom).toDateString()) {
                            return true;
                        }
                        return false;
                    }
                    return ((_b = (_a = x[key]) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.toLowerCase().indexOf((_c = filterModel[key].filter) === null || _c === void 0 ? void 0 : _c.toLowerCase())) > -1;
                });
            });
            const pagedData = filteredData.filter((_x, i) => i >= start && i < end);
            params.success({
                rowData: pagedData.map((x) => (Object.assign({}, x))),
                rowCount: filteredData.length,
            });
        }, 1000);
    };
    const isRowSelectable = (rowNode) => {
        const { data } = rowNode;
        return !data.prevImportDate;
    };
    return (React.createElement(ModalPopup, { isOpen: isOpen, headerText: headerText, onDismiss: () => {
            onDismiss(false);
            setDisableSubmitButton(true);
        }, onSubmit: onSubmit, confirmationButtonDisable: disableSubmitButton, rejectionButtonText: "CANCEL", confirmationButtonText: "IMPORT SELECTED" },
        React.createElement("div", { style: { padding: 5 } },
            isLoading && React.createElement(ProgressIndicator, { label: "Loading..." }),
            listApprovedAppraisers.length > 0 && (React.createElement(Grid, { onSubmit: onSubmit, onBodyGridReady: onGridReady, fields: [...importGridColumns], onGridStateChange: onGridStateChange, suppressAddRowButton: true, suppressEditToggle: true, suppressDeleteButton: true, suppressExcelExport: true, idField: "key", rowsPerPage: 15, suppressRowSpacing: true, gridOptions: {
                    isRowSelectable,
                }, onSelectionChanged: onSelectionChanged, onToggleRefresh: () => {
                    if (gridApi) {
                        gridApi.setFilterModel(null);
                        gridApi === null || gridApi === void 0 ? void 0 : gridApi.deselectAll();
                    }
                }, style: { height: '86vh', display: 'flex', flex: '1 0 auto', flexDirection: 'column' } })))));
};
export default ImportSitusAMCApprovedAppraisers;
