import React from 'react';
import LocationSimilarRecordCheckWrapper from './LocationSimilarRecordCheck.wrapper';
export const LocationSimilarRecordCheckRenderer = (props) => {
    const { node, data, setAcknowledgementRequiredForRow, deleteRows } = props;
    if (!(data === null || data === void 0 ? void 0 : data._newRowId)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(LocationSimilarRecordCheckWrapper, { node: node, data: data, setAcknowledgementRequiredForRow: setAcknowledgementRequiredForRow, deleteRows: deleteRows }));
};
export default LocationSimilarRecordCheckRenderer;
