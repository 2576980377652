var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { toastError, ToastMessage, toastInfo } from '@samc/react-ui-core';
import { useApiContext } from '../../../../api/ApiContext';
import { LinkButton } from '../../../atoms/LinkButton/LinkButton';
import { getAppraiserRegistry } from '../../../../services/AppraiserRegistryService';
export const AppraiserLicenseVerificationRenderer = React.forwardRef((props, ref) => {
    const { node, data } = props;
    const api = useApiContext();
    const [isLoading, setIsLoading] = React.useState(false);
    React.useImperativeHandle(ref, () => ({
        refresh: () => {
            return true;
        },
    }), []);
    const getDataFromAppraiserRegistry = (licenseNo, state, contactId, companyName, effectiveDate, expirationDate) => __awaiter(void 0, void 0, void 0, function* () {
        return getAppraiserRegistry(api.CompanyCatalogApi, licenseNo, state, contactId, companyName, effectiveDate, expirationDate, api.requestInit);
    });
    const onVerifyClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const licenseNo = data === null || data === void 0 ? void 0 : data.LicenseNumber;
        const state = data === null || data === void 0 ? void 0 : data.State;
        const companyName = data === null || data === void 0 ? void 0 : data.CompanyName;
        const effectiveDate = data === null || data === void 0 ? void 0 : data.EffectiveDate;
        const expirationDate = data === null || data === void 0 ? void 0 : data.ExpirationDate;
        const contactId = data === null || data === void 0 ? void 0 : data.ContactId;
        try {
            if (!(data === null || data === void 0 ? void 0 : data.LicenseNumber)) {
                toastInfo(React.createElement(ToastMessage, { title: "Validation", message: "Enter License No" }));
                return;
            }
            if (!(data === null || data === void 0 ? void 0 : data.State)) {
                toastInfo(React.createElement(ToastMessage, { title: "Validation", message: "Enter State" }));
                return;
            }
            let gotProperData = false;
            let appraisers;
            let res;
            setIsLoading(true);
            res = yield getDataFromAppraiserRegistry(licenseNo, state, contactId, companyName);
            appraisers = res.result;
            if (!res.error && appraisers.length > 1 && effectiveDate && expirationDate) {
                res = yield getDataFromAppraiserRegistry(licenseNo, state, contactId, companyName, effectiveDate, expirationDate);
                appraisers = res.result;
            }
            else {
                gotProperData = true;
            }
            if (!gotProperData && !res.error && appraisers.length === 0 && companyName) {
                res = yield getDataFromAppraiserRegistry(licenseNo, state, contactId, '', effectiveDate, expirationDate);
                appraisers = res.result;
            }
            setIsLoading(false);
            if (res.error) {
                toastError(React.createElement(ToastMessage, { title: "Error", message: res.developerMessage }));
            }
            else {
                appraisers = res.result;
                if (!appraisers || appraisers.length === 0) {
                    node.setDataValue('VerificationStatusId', 'NotVerified');
                    toastInfo(React.createElement(ToastMessage, { title: "Info", message: "No record found from Registry" }));
                    return;
                }
                const item = appraisers[0];
                node.setDataValue('VerificationStatusId', '-');
                if (item.company) {
                    node.setDataValue('AppraiserLicenseCompany', item === null || item === void 0 ? void 0 : item.company);
                }
                if (item.status === 'I') {
                    node.setDataValue('LicenseStatusId', 'Inactive');
                }
                else if (item.status === 'A') {
                    node.setDataValue('LicenseStatusId', 'Active');
                }
                if ((item === null || item === void 0 ? void 0 : item.aqb_compliant) === 'Yes') {
                    node.setDataValue('AqbCompliant', '1');
                }
                if ((item === null || item === void 0 ? void 0 : item.aqb_compliant) === 'No') {
                    node.setDataValue('AqbCompliant', '0');
                }
                node.setDataValue('EffectiveDate', item === null || item === void 0 ? void 0 : item.eff_date);
                node.setDataValue('ExpirationDate', item === null || item === void 0 ? void 0 : item.exp_date);
                node.setDataValue('VerificationStatusDate', new Date());
                setTimeout(() => {
                    node.setDataValue('VerificationStatusId', 'Verified');
                }, 500);
            }
        }
        catch (e) {
            toastError(React.createElement(ToastMessage, { title: "Error", message: "Some error in api" }));
            setIsLoading(false);
        }
    });
    if (isLoading) {
        return React.createElement(React.Fragment, null, "Loading...");
    }
    return React.createElement(LinkButton, { style: { padding: '3px' }, text: "Verify", onClick: onVerifyClick });
});
export default AppraiserLicenseVerificationRenderer;
