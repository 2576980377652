import * as React from 'react';
import { LinkButton } from '../../atoms/LinkButton/LinkButton';
import { SimilarRecordModalWrapper } from '../SimilarRecordModal/SimilarRecordModalWrapper';
export const BaseSimilarRecordCheckRenderer = (props) => {
    const { node, data, deleteRows, isLoading, similarRecords, domainId, isAcknowledged, setIsAcknowledged, similarRecordDetectedColumnName, setAcknowledgementRequiredForRow, } = props;
    const { rowIndex } = node;
    const similarRecordCheckData = (data && data[similarRecordDetectedColumnName] ? data[similarRecordDetectedColumnName] : undefined);
    React.useEffect(() => {
        setAcknowledgementRequiredForRow === null || setAcknowledgementRequiredForRow === void 0 ? void 0 : setAcknowledgementRequiredForRow(`${rowIndex}`, similarRecords.length > 0 && !isAcknowledged);
    }, [similarRecords, isAcknowledged, setAcknowledgementRequiredForRow, rowIndex]);
    const [showModal, setShowModal] = React.useState(false);
    const handleClickAcknowledge = React.useCallback(() => setShowModal(true), []);
    const handleSubmitSimilarRecordModal = React.useCallback((value) => {
        if (value === 'ContinueToAdd') {
            setIsAcknowledged(true);
            node.setDataValue(similarRecordDetectedColumnName, Object.assign(Object.assign({}, similarRecordCheckData), { isAcknowledged: true }));
            setShowModal(false);
        }
        if (value === 'CancelAdd') {
            setShowModal(false);
            node.setDataValue(similarRecordDetectedColumnName, { similarRecords: [] });
            if (deleteRows)
                deleteRows([node]);
        }
    }, [deleteRows, node, setIsAcknowledged, similarRecordCheckData, similarRecordDetectedColumnName]);
    if (isAcknowledged) {
        return React.createElement("div", { style: { color: '#214d88' } }, "Acknowledged");
    }
    if (similarRecords.length > 0) {
        return (React.createElement(React.Fragment, null,
            showModal && (React.createElement(SimilarRecordModalWrapper, { rowIndex: -1, onSubmit: handleSubmitSimilarRecordModal, data: similarRecords, domainId: domainId, onDismiss: () => {
                    setShowModal(false);
                } })),
            React.createElement(LinkButton, { text: "Requires Acknowledgement", onClick: handleClickAcknowledge, parentStyle: { backgroundColor: 'pink', textAlign: 'center' } })));
    }
    if (isLoading) {
        return React.createElement(React.Fragment, null, "Loading");
    }
    return React.createElement(React.Fragment, null);
};
export default BaseSimilarRecordCheckRenderer;
