import { apiProvider } from '../api/Provider';
export const getCompanySimilarRecord = (companyCatalogBaseUrl, searchTerm, requestInit) => {
    return apiProvider.getData(`${companyCatalogBaseUrl}/api/Company/SimilarRecord?name=${searchTerm}`, requestInit);
};
export const getContactSimilarRecord = (companyCatalogBaseUrl, firstName, lastName, requestInit) => {
    return apiProvider.getData(`${companyCatalogBaseUrl}/api/Contact/SimilarRecord?firstName=${firstName}&lastName=${lastName}`, requestInit);
};
export const getLocationSimilarRecord = (companyCatalogBaseUrl, companyId, locationName, requestInit) => {
    return apiProvider.getData(`${companyCatalogBaseUrl}/api/Location/SimilarRecord?companyId=${companyId}&locationName=${locationName}`, requestInit);
};
