import React from 'react';
import { SimilarRecordModal } from './SimilarRecordModal';
export const companyGridColumns = [
    { field: 'companyName', headerName: 'Company' },
    { field: 'foundIn', displayNameRule: 'Found In' },
    { field: 'shortName', displayNameRule: 'Short Name' },
    { field: 'companyType', displayNameRule: 'Company Type' },
    { field: 'companyStatus', displayNameRule: 'Company Status' },
    { field: 'globalDateImported', displayNameRule: 'Date Imported' },
];
export const contactGridColumns = [
    { field: 'companyName', headerName: 'Company' },
    { field: 'foundIn', headerName: 'Found In' },
    { field: 'name', displayNameRule: 'Name' },
    { field: 'emailAddress', displayNameRule: 'Email Address' },
    { field: 'contactStatus', displayNameRule: 'Contact Status' },
    { field: 'globalDateImported', displayNameRule: 'Date Imported' },
];
export const locationGridColumns = [
    { field: 'locationName', displayNameRule: 'Location Name' },
    { field: 'foundIn', headerName: 'Found In' },
    { field: 'locationType', displayNameRule: 'Location Type' },
    { field: 'locationCountry', displayNameRule: 'Location Country' },
    { field: 'locationCity', displayNameRule: 'Location City' },
    { field: 'locationStatus', displayNameRule: 'Location Status' },
    { field: 'globalDateImported', displayNameRule: 'Date Imported' },
];
export const SimilarRecordModalWrapper = (props) => {
    const { rowIndex, domainId, data, onDismiss, onSubmit } = props;
    if (domainId === 'Company' || domainId === 'Contact' || domainId === 'Location') {
        let columnName = [];
        let title = '';
        if (domainId === 'Company') {
            columnName = companyGridColumns;
            title = 'Acknowledge Similar Companies';
        }
        else if (domainId === 'Contact') {
            columnName = contactGridColumns;
            title = 'Acknowledge Similar Contacts';
        }
        else if (domainId === 'Location') {
            columnName = locationGridColumns;
            title = 'Acknowledge Similar Locations';
        }
        return (React.createElement(SimilarRecordModal, { isOpen: true, colunmName: columnName, data: data, headerText: title, onDismiss: onDismiss, rowIndex: rowIndex, onSubmit: onSubmit }));
    }
    return React.createElement(React.Fragment, null);
};
export default SimilarRecordModalWrapper;
